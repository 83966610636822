<template>
  <div>
    <Loading v-show="show" />
    <div class="background-area overlay-dark-fullwidth no-animation">
      <img id="ImagesBID" style="width: 100%" />
      <div class="banner-content static-text">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="text-content-wrapper text-center full-width">
                <div class="text-content table-cell">
                  <h1
                    class="title1 cd-headline push text-center border-none mt-40"
                  >
                    <span class="cd-words-wrapper">
                      <b id="postTitleId" class="is-visible"> </b>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-form-area section-padding" v-show="showAbout">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12">
            <div class="contact-text">
              <p v-for="about in About" :key="about.postID">
                <span class="c-icon"><i class="fa fa-chevron-left"></i></span
                ><span class="c-text"
                  ><a @click="getAboutById(about.postID)">{{
                    about.postTitle
                  }}</a></span
                >
              </p>

              <p v-for="leader in Leader" :key="leader.postId">
                <span class="c-icon"><i class="fa fa-chevron-left"></i></span
                ><span class="c-text">
                  <router-link
                    :to="{
                      name: 'Leader-with-id-And-Type',
                      params: {
                        type: 'Univ.Branshes.Leader',
                        id: leader.postID,
                      },
                    }"
                  >
                    {{ leader.postTitle }}</router-link
                  ></span
                >
              </p>
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="course-details-content">
              <div class="single-course-details">
                <div class="row">
                  <div class="col-md-12">
                    <div class="single-item-text">
                      <h5 id="postTitle_"></h5>
                      <p id="postSubTitle_"></p>
                      <p id="postDetails_"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="event-area section-padding" style="background:#f8f8f8" v-show="showActivity">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3 style="margin-bottom: 24px">{{ BranshActivity }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            style="margin-top: 24px"
            v-for="event in Events"
            :key="event.postId"
            class="col-lg-3 col-md-6 col-12"
          >
            <div class="single-event-item">
              <div class="single-event-image">
                <a href="javascript:void(0)">
                  <img
                    v-bind:src="
                      'https://api2.yg-u.net/images/post/' + event.postImage
                    "
                  />
                  <span
                    ><span>{{ event.day1 }}</span
                    >{{ event.nT_MONTH1 }}</span
                  >
                </a>
              </div>
              <div class="single-event-text">
                <h6>
                  <a
                    href="javascript:void(0)"
                    style="line-height: 2; text-align: justify"
                    >{{ event.postTitle }}
                  </a>
                </h6>
                <!-- <div class="single-item-comment-view">
                  <span><i class="fa fa-clock-o"></i>{{ event.time1 }}</span>
                  <span><i class="fa fa-map-pin"></i>{{ event.txtAdress1 }} </span>
                </div> -->
                <p style="text-align: justify">
                  {{ event.postSubTitle }}
                </p>

                <router-link
                  class="button-default"
                  :to="{
                    name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
                    params: {
                      type: 'Univ.Branshes.Activity',
                      id: event.postID,
                      BrnId: event.brnID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 text-center">
          <router-link
            :to="{
              name: 'Department-with-BrnId-And-Type',
              params: {
                type: 'Univ.Branshes.Activity',
                BrnId: $route.params.id,
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showAbout: true,
      showActivity: true,
      About: [],
      Leader: [],
      Events: [],

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      BtnMore: "",
      Detials: "",
      BranshActivity: "",
    };
  },
  methods: {
    getAboutById(id) {
      var self = this;
      if (localStorage.getItem("lang") == null) {
        //translate
        self.BtnMore = self.ArTranslat[0]["Home"]["BtnDetials"];
        self.Detials = self.ArTranslat[0]["Home"]["Detials"];
        self.BranshActivity = self.ArTranslat[0]["Home"]["BranshActivity"];

        var bodyFormData = new FormData();
        bodyFormData.append("check", "getPostById");
        bodyFormData.append("Lang", localStorage.getItem("lang"));
        bodyFormData.append("PostId", id);
        bodyFormData.append("FbrnId", "");
        bodyFormData.append("Type", "Univ.Branshes.About");
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "1");
        axios({
          method: "post",
          url: "https://api2.yg-u.net/our_team/getPost",
          data: bodyFormData,
        })
          .then(function (response) {
            document.getElementById("postTitle_").innerText =
              response.data[0]["postTitle"];
            document.getElementById("postSubTitle_").innerText =
              response.data[0]["postSubTitle"];
            document.getElementById("postDetails_").innerHTML =
              response.data[0]["postDetails"];
          })
          .catch(function (response) {
            // eslint-disable-next-line no-console
            console.log("error", response);
          });
      } else {
        //translate
        if (localStorage.getItem("lang") == "Ar") {
          self.translate = self.ArTranslat;
        } else {
          self.translate = self.EnTranslat;
        }
        self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.BranshActivity = self.ArTranslat[0]["Home"]["BranshActivity"];

        var bodyFormData1 = new FormData();
        bodyFormData1.append("check", "getPostById");
        bodyFormData1.append("Lang", localStorage.getItem("lang"));
        bodyFormData1.append("PostId", id);
        bodyFormData1.append("FbrnId", "");
        bodyFormData1.append("Type", "Univ.Branshes.About");
        bodyFormData1.append("ParentId", "NTROOT0");
        bodyFormData1.append("PostName", "");
        bodyFormData1.append("Pno", "1");
        axios({
          method: "post",
          url: "https://api2.yg-u.net/our_team/getPost",
          data: bodyFormData1,
        })
          .then(function (response) {
            document.getElementById("postTitle_").innerText =
              response.data[0]["postTitle"];
            document.getElementById("postSubTitle_").innerText =
              response.data[0]["postSubTitle"];
            document.getElementById("postDetails_").innerHTML =
              response.data[0]["postDetails"];
          })
          .catch(function (response) {
            // eslint-disable-next-line no-console
            console.log("error", response);
          });
      }
    },
    getApis() {
      var self = this;
      var ID = this.$route.params.id;

      var bodyFormData3 = new FormData();
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("Id", ID);

      axios({
        method: "post",
        url: "https://api2.yg-u.net/branches/GetBrnWebSites",
        data: bodyFormData3,
      })
        .then(function (response) {
          var BrnData;
          BrnData = response.data;
          if (BrnData.length != 0) {
            document.getElementById("postTitleId").innerText =
              response.data[0]["brnTitle"];
            document
              .getElementById("ImagesBID")
              .setAttribute(
                "src",
                "https://api2.yg-u.net/images/branches/" +
                  response.data[0]["brnImage"]
              );
          }

          var bodyFormData = new FormData();

          bodyFormData.append("check", "getPost");
          bodyFormData.append("Lang", localStorage.getItem("lang"));
          bodyFormData.append("PostId", "");
          bodyFormData.append("FbrnId", ID);
          bodyFormData.append("Type", "Univ.Branshes.About");
          bodyFormData.append("ParentId", "NTROOT0");
          bodyFormData.append("PostName", "");
          bodyFormData.append("Pno", "20");
          axios({
            method: "post",
            url: "https://api2.yg-u.net/our_team/getPost",
            data: bodyFormData,
          })
            .then(function (response) {
              self.About = response.data;
              if (self.About.length != 0) {
                self.showAbout = true;
                self.getAboutById(response.data[0]["postID"]);
              }
              else{
                self.showAbout = false;
              }
              var bodyFormData = new FormData();

              bodyFormData.append("check", "getPost");
              bodyFormData.append("Lang", localStorage.getItem("lang"));
              bodyFormData.append("FbrnId", ID);
              bodyFormData.append("Type", "Univ.Branshes.Leader");
              bodyFormData.append("ParentId", "NTROOT0");
              bodyFormData.append("PostName", "");
              bodyFormData.append("Pno", "6");
              axios({
                method: "post",
                url: "https://api2.yg-u.net/our_team/getPost",
                data: bodyFormData,
              })
                .then(function (response) {
                  self.Leader = response.data;
                 
                  var bodyFormData = new FormData();
                  bodyFormData.append("check", "getPost");
                  bodyFormData.append("Lang", localStorage.getItem("lang"));
                  bodyFormData.append("FbrnId", ID);
                  bodyFormData.append("Type", "Univ.Branshes.Activity");
                  bodyFormData.append("ParentId", "NTROOT0");
                  bodyFormData.append("PostName", "");
                  bodyFormData.append("Pno", "-1");
                  axios({
                    method: "post",
                    url: "https://api2.yg-u.net/our_team/getPost",
                    data: bodyFormData,
                  })
                    .then(function (response) {
                      self.Events = response.data;
                      if (self.Events.length != 0) {
                        self.showActivity = true;
                      } else {
                        self.showActivity = false;
                      }
                      document.getElementById("VueMainJsNewTouch")?.remove();
                      let recaptchaScript = document.createElement("script");
                      recaptchaScript.setAttribute("src", "/js/main.js");
                      recaptchaScript.id = "VueMainJsNewTouch";
                      document.head.appendChild(recaptchaScript);

                      setTimeout(() => {
                        self.show = false;
                        document.getElementById('YG_U').setAttribute('style','display:none !important')

                      }, 100);
                    })
                    .catch(function (response) {
                      // eslint-disable-next-line no-console
                      console.log("error", response);
                    });
                })
                .catch(function (response) {
                  // eslint-disable-next-line no-console
                  console.log("error", response);
                });
            })
            .catch(function (response) {
              // eslint-disable-next-line no-console
              console.log("error", response);
            });
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        if (response.data[0]["LangId"] == "Ar") {
          self.translate = self.ArTranslat;
          self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
          self.Detials = self.translate[0]["Home"]["Detials"];
          self.BranshActivity = self.ArTranslat[0]["Home"]["BranshActivity"];

          document.getElementById("langfilewebsites")?.remove();
        } else {
          self.translate = self.EnTranslat;
          self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
          self.Detials = self.translate[0]["Home"]["Detials"];
          self.BranshActivity = self.ArTranslat[0]["Home"]["BranshActivity"];

          let link1 = document.createElement("link");
          link1.setAttribute("rel", "stylesheet");
          link1.id = "langfilewebsites";
          link1.setAttribute("href", "../../style-ltr.css");
          document.head.appendChild(link1);
        }
        self.getApis();
      });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.BranshActivity = self.ArTranslat[0]["Home"]["BranshActivity"];

        document.getElementById("langfilewebsites")?.remove();
      } else {
        self.translate = self.EnTranslat;
        self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.BranshActivity = self.ArTranslat[0]["Home"]["BranshActivity"];

        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../style-ltr.css");
        document.head.appendChild(link1);
      }

      self.getApis();
    }
  },
};
</script>
<style scoped>
.newsletter-area {
  background: #3498db none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}

.c-text a {
  cursor: pointer;
}
.ImagesBID {
  width: 100% !important;
}
.contact-text p {
  background: #3498db;
  padding: 11px;
  color: #fff;
  border-radius: 10px;
}
.contact-text p span a {
  color: #fff;
}
.single-item-text {
  padding: 15px;
}
.course-details-content {
  margin: 0;
}
.single-item-text h5 {
  font-weight: bold;
}
.single-item-text p {
  margin-top: 10px;
  text-align: justify;
  line-height: 30px;
}
.single-course-details {
  padding: 10px 0 !important;
  margin-bottom: 0;
  box-shadow: -1px 8px 15px 5px #ccc;
}
.about-container h3 {
  color: #000;
}
.header-logo-menu.stick.semi-transparent,
.gallery-img,
.gallery-img .hover-effect,
.single-teacher-image > a:after {
  background: rgb(52 152 219 / 63%) none repeat scroll 0 0;
}
#gelery {
  padding: 10px;
}
#groupImages {
  background: #3498db;
  padding: 11px;
  color: #fff;
  border-radius: 30px;
}
.btn-warning {
  text-align: center;
  margin-top: 30px;
}
</style>
